<template>
  <div class="claim-vehicle-cell cells-cmn__cell">
    <component
      :is="vehicle.id ? 'a' : 'div'"
      class="claim-vehicle-cell__link claim-vehicle-cell__img-wrp"
      :href="lotLink"
      target="_blank"
      rel="nofollow noopener"
    >
      <ui-img
        class="claim-vehicle-cell__img"
        v-bind="lazyVehicleImg"
        :title="vehicle.name"
        :alt="vehicle.id"
      />
    </component>

    <div class="cells-cmn__cell-col">
      <component
        :is="vehicle.id ? 'a' : 'span'"
        class="
          claim-vehicle-cell__name
          cells-cmn__str
          cells-cmn__str_nb
        "
        :href="lotLink"
        target="_blank"
        rel="nofollow noopener"
        :title="vehicle.name"
      >
        {{ vehicle.name }}
      </component>

      <span
        v-if="vehicle.id"
        class="claim-vehicle-cell__desc cells-cmn__str cells-cmn__str_sec"
      >
        <span>{{ $t('ID_FORMAT', { id: vehicle.id }) }}</span>

        <ui-copy-button
          :value="vehicle.id"
          :copied-message="$t('COMMON.COPIED_MSG')"
        />
      </span>

      <span
        v-if="vehicle.vin"
        class="claim-vehicle-cell__desc cells-cmn__str cells-cmn__str_sec"
      >
        <span>{{ $t('VIN_FORMAT', { vin: vehicle.vin }) }}</span>

        <ui-copy-button
          :value="vehicle.vin"
          :copied-message="$t('COMMON.COPIED_MSG')"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { UiImg, UiCopyButton } from '@shelf.network/ui-kit'
import { ExtensionVehicle } from 'Models/ExtensionVehicle'

import { lazyTinyImg } from 'Utils/generateImageProps'
import placeholderPath from 'Assets/icons/placeholder.svg'

export default {
  name: 'claim-vehicle-cell',
  components: {
    UiImg,
    UiCopyButton,
  },

  props: {
    vehicle: { type: ExtensionVehicle, required: true },
  },

  computed: {
    lazyVehicleImg () {
      return this.vehicle.imageUrl
        ? lazyTinyImg(this.vehicle.imageUrl, true)
        : { src: placeholderPath }
    },

    lotLink () {
      return `https://www.copart.com/lot/${this.vehicle.id}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/cells/cells.scss";

.claim-vehicle-cell {
  &__img-wrp {
    margin-right: 0.9em;
  }

  &__img {
    --ui-img-border-radius: 5px;

    display: block;
    height: 5.2em;
    max-width: 6.4em;
    min-width: 6.4em;
  }

  &__name {
    text-decoration: none;
    color: $color-dark;
    font-size: 1.1em;
  }

  &__desc {
    font-size: 0.9em;
  }

  &__location {
    font-size: 0.7em;
    line-height: 1.5;
    font-weight: 500;
  }
}
</style>

<i18n>
{
  "en": {
    "ID_FORMAT": "ID: #{id}",
    "VIN_FORMAT": "Vin: {vin}"
  },
  "ka": {
    "ID_FORMAT": "ID: #{id}",
    "VIN_FORMAT": "Vin: {vin}"
  },
  "ru": {
    "ID_FORMAT": "ID: #{id}",
    "VIN_FORMAT": "Vin: {vin}"
  },
  "uk": {
    "ID_FORMAT": "ID: #{id}",
    "VIN_FORMAT": "Vin: {vin}"
  }
}
</i18n>
