<template>
  <div class="claim-actions-cell cells-cmn__cell">
    <button
      class="claim-actions-cell__btn claim-actions-cell__btn_success"
      :title="$t('APPROVE_BTN_HINT')"
      :disabled="isProcessing"
      @click="approve"
    >
      <ui-icon
        class="claim-actions-cell__btn-icon"
        icon="success"
      />
    </button>
  </div>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'

import { mapActions } from 'vuex'
import { extensionClaimsActions } from '../store/types'

import { ClaimRequest } from 'Models/ClaimRequest'
import { showError, showSuccess } from 'Utils/notifications'

export default {
  name: 'claim-actions-cell',
  components: { UiIcon },

  props: {
    item: { type: ClaimRequest, required: true },
  },

  data () {
    return {
      isProcessing: false,
    }
  },

  methods: {
    ...mapActions('ui/extension-claims', {
      approveRequest: extensionClaimsActions.APPROVE_REQUEST,
    }),

    async approve () {
      const lotName = this.item.name
      if (!confirm(this.$t('APPROVE_CONFIRM_MSG', { lotName }))) {
        return
      }

      this.isProcessing = true
      try {
        await this.approveRequest({
          id: this.item.id,
          lotSaleId: this.item.lotSaleId
        })
        showSuccess(this.$t('APPROVE_SUCCESS_NOTIFY'))
      } catch (error) {
        showError(this.$t('APPROVE_FAILED_NOTIFY'))
        console.error(error)
      }
      this.isProcessing = false
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/common/cells/cells.scss";

.claim-actions-cell {
  margin-left: auto;

  &__btn {
    color: $color-white;
    background: $color-flag-is-success;
    padding: 0;
    height: 3.5em;
    width: 3.5em;
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 150ms;

    &:disabled {
      cursor: not-allowed;
      color: $color-grey;
      background: $color-light-grey;
    }

    &:hover:not(:disabled) {
      background: mix($color-flag-is-success, $color-dark, 85);
    }

    &-icon {
      font-size: 1.5em;

      &_open {
        transform: rotate(180deg);
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "APPROVE_BTN_HINT": "Approve request",
    "APPROVE_CONFIRM_MSG": "Approve request on lot {lotName}? The action is irreversible.",
    "APPROVE_SUCCESS_NOTIFY": "Request has been approved.",
    "APPROVE_FAILED_NOTIFY": "Cannot approve request. Please try again later or contact the system owner."
  },
  "ka": {
    "APPROVE_BTN_HINT": "დადასტურება",
    "APPROVE_CONFIRM_MSG": "მოთხოვნის დადასტურება {lotName}? უკან ცვლილება ვეღარ მოხდება.",
    "APPROVE_SUCCESS_NOTIFY": "მოთხოვნა დადასტურდა.",
    "APPROVE_FAILED_NOTIFY": "მოთხოვნა არ დადასტურდა. სცადეთ მოგვიანებით ან მიმართეთ ადმინისტრატორს."
  },
  "ru": {
    "APPROVE_BTN_HINT": "Одобрить запрос",
    "APPROVE_CONFIRM_MSG": "Одобрить запрос на лот {lotName}? Действие необратимо.",
    "APPROVE_SUCCESS_NOTIFY": "Запрос одобрен.",
    "APPROVE_FAILED_NOTIFY": "Не удалось одобрить запрос. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "APPROVE_BTN_HINT": "Схвалити запит",
    "APPROVE_CONFIRM_MSG": "Схвалити запит на лот {lotName}? Дія незворотна.",
    "APPROVE_SUCCESS_NOTIFY": "Запит схвалено.",
    "APPROVE_FAILED_NOTIFY": "Не вдалося схвалити запит. Повторіть спробу пізніше або зв’яжіться з власником системи."
  }
}
</i18n>
