<template>
  <div class="claim-identity-cell cells-cmn__cell">
    <lazy-ava-img
      class="cells-cmn__ava"
      :alt="identity.fullName"
      :title="identity.fullName"
      :src="identity.avatarLink"
    />

    <div class="claim-identity-cell__name">
      <component
        :is="$can($USER_CLAIMS.EXTENSION_DEALERS) ? 'router-link' : 'p'"
        :to="{
          name: 'extension.copart.dealers.entry',
          params: { dealerId: identity.id }
        }"
        class="cells-cmn__btn claim-identity-cell__link"
      >
        <span
          class="cells-cmn__str cells-cmn__str_nb"
          :title="identity.fullName"
        >
          {{ identity.fullName }}
        </span>
      </component>

      <template v-if="identity.email">
        <a
          class="claim-identity-cell__link claims-cmn__str
          claims-cmn__str_nb claims-cmn__str_sec
        "
          :title="identity.email"
          :href="`mailto:${identity.email}`"
        >
          {{ identity.email }}
        </a>
      </template>

      <template v-else>
        <span class="claims-cmn__str claims-cmn__str_sec">
          {{ $t('COMMON.SYMBOLS.MDASH') }}
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import LazyAvaImg from 'Common/LazyAvaImg'
import { Identity } from 'Models/Identity'

export default {
  name: 'claim-identity-cell',
  components: { LazyAvaImg },

  props: {
    identity: { type: Identity, required: true },
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/cells/cells.scss";
@import "../styles/claims.scss";

.claim-identity-cell {
  &__name {
    display: grid;
  }

  &__link {
    text-decoration: none;
  }
}
</style>
