<template>
  <div class="claims-list-head claims-cmn__row">
    <span class="claims-list-head__cell">
      {{ $t('VEHICLE_HEAD_CELL') }}
    </span>

    <span class="claims-list-head__cell">
      {{ $t('DEALER_HEAD_CELL') }}
    </span>

    <span class="claims-list-head__cell">
      {{ $t('COPART_LOGIN_HEAD_CELL') }}
    </span>

    <span class="claims-list-head__cell">
      {{ $t('DOC_TYPE_HEAD_CELL') }}
    </span>

    <span class="claims-list-head__cell">
      {{ $t('LOCATION_HEAD_CELL') }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'claims-list-head',
}
</script>

<style lang="scss" scoped>
@import "../styles/claims.scss";

.claims-list-head {
  padding: 2em 3em 0;

  &__cell {
    @extend .claims-cmn__cell;

    line-height: 1.4;
    color: $color-dark-grey;
  }
}
</style>

<i18n>
{
  "en": {
    "VEHICLE_HEAD_CELL": "VEHICLE",
    "DEALER_HEAD_CELL": "DEALER",
    "COPART_LOGIN_HEAD_CELL": "COPART LOGIN",
    "DOC_TYPE_HEAD_CELL": "DOC TYPE",
    "LOCATION_HEAD_CELL": "LOCATION"
  },
  "ka": {
    "VEHICLE_HEAD_CELL": "VEHICLE",
    "DEALER_HEAD_CELL": "ᲐᲕᲢᲝᲛᲝᲑᲘᲚᲘ",
    "COPART_LOGIN_HEAD_CELL": "COPART-ᲖᲔ ᲨᲔᲡᲕᲚᲐ",
    "DOC_TYPE_HEAD_CELL": "ᲓᲝᲙ. ᲢᲘᲞᲘ",
    "LOCATION_HEAD_CELL": "ᲚᲝᲙᲐᲪᲘᲐ"
  },
  "ru": {
    "VEHICLE_HEAD_CELL": "АВТО",
    "DEALER_HEAD_CELL": "ДИЛЕР",
    "COPART_LOGIN_HEAD_CELL": "ЛОГИН COPART",
    "DOC_TYPE_HEAD_CELL": "ТИП ДОКУМЕНТА",
    "LOCATION_HEAD_CELL": "ЛОКАЦИЯ"
  },
  "uk": {
    "VEHICLE_HEAD_CELL": "АВТО",
    "DEALER_HEAD_CELL": "ДИЛЕР",
    "COPART_LOGIN_HEAD_CELL": "ЛОГІН COPART",
    "DOC_TYPE_HEAD_CELL": "ТИП ДОКУМЕНТА",
    "LOCATION_HEAD_CELL": "ЛОКАЦІЯ"
  }
}
</i18n>
