<template>
  <list-grouped
    class="claims-list"
    :list="list"
    :list-total-count="0"
    group-by-date-field="createdAt"
    :is-loading="isLoading"
    :is-next-loading="isNextLoading"
    :page-size="CLAIMS_PAGE_LIMIT"
    :load-more="loadMore"
    :head-component="ClaimsListHead"
    :item-component="ClaimItem"
    :item-skeleton-component="ClaimItemSkeleton"
    @update-list-ask="$emit('update-list-ask')"
  />
</template>

<script>
import ListGrouped from 'Common/ListGrouped'

import ClaimItem from './ClaimItem'
import ClaimItemSkeleton from './ClaimItemSkeleton'
import ClaimsListHead from './ClaimsListHead'

import { mapGetters } from 'vuex'
import { extensionClaimsGetters } from '../store/types'

import { CLAIMS_PAGE_LIMIT } from '../constants'
import { validateArrayOf } from 'Models/modelUtils'
import { ClaimRequest } from 'Models/ClaimRequest'

export default {
  name: 'claims-list',
  components: { ListGrouped },

  props: {
    list: {
      type: Array,
      required: true,
      validator: validateArrayOf(ClaimRequest),
    },

    loadMore: {
      type: Function,
      required: true,
    },
  },

  data () {
    return {
      ClaimItem,
      ClaimItemSkeleton,
      ClaimsListHead,
      CLAIMS_PAGE_LIMIT,
    }
  },

  computed: {
    ...mapGetters('ui/extension-claims', {
      isLoading: extensionClaimsGetters.IS_LOADING,
      isNextLoading: extensionClaimsGetters.IS_NEXT_LOADING,
    }),
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/claims.scss";

.claims-list {
  --list-padding-side: 3em;
}
</style>
