<template>
  <div class="claim-item">
    <div class="claims-cmn__row">
      <claim-vehicle-cell :vehicle="item.vehicle" />
      <claim-identity-cell :identity="item.identity" />

      <component
        :is="$can($USER_CLAIMS.EXTENSION_ACCOUNTS) ? 'router-link' : 'p'"
        :to="{
          name: 'extension.copart.accounts.entry',
          query: { search: item.copartLogin }
        }"
        class="cells-cmn__cell cells-cmn__str cells-cmn__str_sec"
        :class="{
          'cells-cmn__str_link': $can($USER_CLAIMS.EXTENSION_ACCOUNTS)
        }"
      >
        {{ item.copartLogin }}
      </component>

      <div class="cells-cmn__cell">
        <span class="cells-cmn__str cells-cmn__str_sec">
          <span>
            {{ item.vehicle.docType || $t('COMMON.SYMBOLS.MDASH') }}
          </span>
        </span>
      </div>

      <div class="cells-cmn__cell">
        <span class="cells-cmn__str cells-cmn__str_sec">
          {{ item.vehicle.location }}
        </span>
      </div>

      <claim-actions-cell :item="item" />
    </div>
  </div>
</template>

<script>
import ClaimVehicleCell from './ClaimVehicleCell'
import ClaimIdentityCell from './ClaimIdentityCell'
import ClaimActionsCell from './ClaimActionsCell'

import { ClaimRequest } from 'Models/ClaimRequest'

export default {
  name: 'claim-item',
  components: {
    ClaimVehicleCell,
    ClaimIdentityCell,
    ClaimActionsCell,
  },

  props: {
    item: {
      type: ClaimRequest,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/cells/cells.scss";
@import "../styles/claims";

.claim-item {
  padding: 2.1em 0;
  border-bottom: 1px solid rgba($color-grey, 0.5);
}
</style>
